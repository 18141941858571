import {
  appStoreSingularTrackingUrl,
  handleAppInstallConversion,
  playStoreSingularTrackingUrl,
} from '../../../constants';
import styles from './PlayStoreBtns.module.scss';
import React from 'react';

const PlayStoreBtns = () => {
  return (
    <>
      <div className={`${styles.playStoreBtnsContainer}`}>
        <div
          className={`cursor-pointer`}
          onClick={() => {
            handleAppInstallConversion(appStoreSingularTrackingUrl);
          }}
        >
          <div loading="lazy" className={`${styles.appStoreBtnImage}`} />
        </div>
        <div
          className={`cursor-pointer`}
          onClick={() => {
            handleAppInstallConversion(playStoreSingularTrackingUrl);
          }}
        >
          <div loading="lazy" className={`${styles.playStoreBtnImage}`} />
        </div>
      </div>
    </>
  );
};

export default PlayStoreBtns;
