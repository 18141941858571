import { PlayStoreBtns } from '../../common';
import styles from './CreatorTamilSection3.module.scss';
import React from 'react';

const CreatorTamilSection3 = () => {
  const tipsContainerPoints = [
    'கதைகள், மென்டல் ஹெல்த்',
    'காதல் மற்றும் இன்டிமேசி',
    'செய்திகள், சினிமா விமர்சனங்கள், கிசு கிசு போன்ற விஷயங்கள்இன்னும் உங்க மனதில் தோன்றும் எல்லாமே பகிர்ந்துக்கலாம்.',
    'மற்றும் உங்கள் மனதில் தோன்றும் அனைத்தும்.',
  ];
  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.titleContainer}`}>
          <div className={`${styles.titleText1}`}>Arré Voice-ஐ பயன்படுத்துவது எப்படி?</div>
          <div className={`${styles.titleText2}`}>
          Arré Voice என்றால் என்ன மற்றும் இந்த பிளாட்ஃபார்மில் எவ்வாறு பதிவுகளை உருவாக்குவது என்பதற்கான அடிப்படைகளை அறிக
          </div>
        </div>
        <div className={`${styles.bodyContainer}`}>
          <div className={`${styles.container1}`}>
            <div className={`${styles.subContainer1}`}>
              <div className={`${styles.subContainer1TextContainer}`}>
                <div className={`${styles.subContainer1Text1}`}>Voicepods</div>
                <div className={`${styles.subContainer1Text2}`}>
                  புதுமையான பதிவுகளை நீங்கள் வெறும் 30 வினாடிகளில்  பதிவேற்ற முடியும்.
                </div>
              </div>
              <div loading="lazy" className={`${styles.subContainer1Img}`} />
            </div>
            <div className={`${styles.subContainer2}`}>
              <div loading="lazy" className={`${styles.subContainer2Img}`} />
              <div className={`${styles.subContainer2TextContainer}`}>
                <div className={`${styles.subContainer2Text1}`}>Creator Studio</div>
                <div className={`${styles.subContainer2Text2}`}>
                உங்கள் பாக்கெட்டிலேயே
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.textContainer1}`}>Let me show you how to create</div>
          <div loading="lazy" className={`${styles.container2}`} />
          <div className={`${styles.textContainer2}`}>
           பின் <span className={`${styles.textContainer2SubText}`}>போஸ்ட்! </span>செய்யுங்கள். இது அவ்வளவு சுலபம்.
          </div>
          <PlayStoreBtns />
          <div loading="lazy" className={`${styles.divider1}`} />
          <div className={`${styles.container3}`}>
            <div className={`${styles.subContainer3}`}>
              <div className={`${styles.subContainer3TextContainer}`}>
                <div className={`${styles.subContainer3Text1}`}>யாரெல்லாம் க்ரியேட்டர் ஆகலாம்?</div>
                <ol style={{ listStyleType: 'initial' }} className={`${styles.subContainer3Points}`}>
                  <li>
                  உங்க ஆழமான ரகசியங்கள், அனுபவங்கள், மனநிலை எல்லாத்தையும் உள்ளயே வெச்சிருக்கிங்களா?
                  </li>
                  <li>உங்கள் குரல் மக்களுடன் இணைக்க முடியும்</li>
                  <li>உங்களுக்கு 60 வினாடிகள் உள்ளன</li>
                </ol>
                <div className={`${styles.subContainer3Text3}`}> அப்போ, நீங்களும் க்ரியேட்டர் ஆகலாம்!!!</div>
              </div>
              <div loading="lazy" className={`${styles.cont3bgIllustration}`} />
              <div loading="lazy" className={`${styles.cont3BgStar}`} />
            </div>
            <div loading="lazy" className={`${styles.subContainer4}`} />
          </div>
          <div loading="lazy" className={`${styles.divider2}`} />
          <div className={`${styles.container4}`}>
            <div className={`${styles.textContainer3}`}>
              {/* <div>பகுதிகள் என்ன?</div> */}
              <div>என்ன என்ன க்ரியேட் பண்ணலாம்?</div>
            </div>
            <div className={`${styles.subContainer5}`}>
              <div loading="lazy" className={`${styles.cont4bgIllustration}`} />
              <div className={`${styles.tipsContainer}`}>
                {tipsContainerPoints.map((point, index) => (
                  <div key={index + Math.random()} className={`${styles.pointContainer}`}>
                    <img alt="Voice Icon" src="/assets/images/ce_s3_voiceImg.svg" width={28} height={28} />
                    <div className={`${styles.pointText}`}>{point}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`${styles.container5}`}>
            <div className={`${styles.subContainer6}`}>
              <div className={`${styles.subContainer3TextContainer}`}>
                <div className={`${styles.subContainer3Text1}`}>
                  <div>உங்கள் கருத்துக்களை</div>
                  <div>மனதில் இருந்து</div>
                  <div>எப்பொழுதும், எங்கும் </div>
                  <div>உங்கள் கருத்தைப் </div>
                  <div>பேசுங்கள்.</div>
                </div>
                <ol style={{ listStyleType: 'initial' }} className={`${styles.subContainer3Points}`}>
                  <li>கேமரா தேவை இல்லை</li>
                  <li>ஸ்டூடியோ செட் தேவை இல்லை</li>
                  <li>Record பட்டனை தட்டுங்கள், ஆரம்பியுங்கள்.</li>
                </ol>
              </div>
              <div loading="lazy" className={`${styles.subCont6BgIllustration}`} />
              <div loading="lazy" className={`${styles.subCont6BgStar}`} />
            </div>
            <div className={`${styles.subContainer7}`}>
              <div className={`${styles.subContainer3TextContainer}`}>
                <div className={`${styles.subContainer3Text1}`}>பணம் சம்பாதிக்கணுமா?</div>
                <ol style={{ listStyleType: 'initial' }} className={`${styles.subContainer3Points}`}>
                  <li>பிராண்ட் மற்றும் டீல்ஸ் தேர்ந்து எடுத்து கொள்ளுங்கள்.</li>
                  <li>Followers அதிக படுத்துங்கள்</li>
                </ol>
              </div>
              <div loading="lazy" className={`${styles.subCont7BgIllustration}`} />
              <div loading="lazy" className={`${styles.subCont7BgStar}`} />
            </div>
          </div>
          <div className={`${styles.bottomPlayStoreBtns}`}>
            <PlayStoreBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorTamilSection3;
