import { Footer, Header } from '../common';
import FooterSocialInfo from '../footerSocialInfo/FooterSocialInfo';
import styles from './CreatorTamilPage.module.scss';
import React from 'react';
import CreatorTamilSection1 from './creatorTamilSection1/CreatorTamilSection1';
import CreatorTamilSection2 from './creatorTamilSection2/CreatorTamilSection2';
import CreatorTamilSection3 from './creatorTamilSection3/CreatorTamilSection3';

const CreatorTamilPage = () => {
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`header-container ${styles.headerContainer}`}>
          <Header />
        </div>
        <div className={`${styles.contentWrapper}`}>
          <div className={`${styles.section1Container}`}>
            <CreatorTamilSection1 />
          </div>
          <div className={`${styles.section2Container}`}>
            <CreatorTamilSection2 />
          </div>
          <div className={`${styles.section3Container}`}>
            <CreatorTamilSection3 />
          </div>
          {/*footer */}
          <FooterSocialInfo />
        </div>
        <div className={`${styles.footerContainer}`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CreatorTamilPage;
