import { PlayStoreBtns } from '../../common';
import styles from './CreatorEnglishSection1.module.scss';
import React from 'react';

const CreatorEnglishSection1 = () => {
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.textContainer}`}>
            <div className={`${styles.text1}`}>
              Join the audio content creation bandwagon on India’s only women-first, short format audio social app -{' '}
              <span className={`${styles.subtext1}`}>Arré Voice</span>.
            </div>
          </div>
          <div className={`${styles.text2}`}>Are you someone who</div>
          <div className={`${styles.illustrationsContainer}`}>
            <div className={`${styles.illustrationContainer}`}>
              <div loading="lazy" className={`${styles.illustrationImg1}`} />
              <div className={`${styles.illText1}`}>Speaks their heart and wants people to hear</div>
            </div>
            <div className={`${styles.illustrationContainer2}`}>
              <div loading="lazy" className={`${styles.illustrationImg2}`} />
              <div className={`${styles.illText2}`}>Sings their heart out in the shower</div>
            </div>
            <div className={`${styles.illustrationContainer}`}>
              <div loading="lazy" className={`${styles.illustrationImg3}`} />
              <div className={`${styles.illText3}`}>Wants to make a mark in the digital era with their voice</div>
            </div>
          </div>
          <div className={`${styles.text3}`}>Then...</div>
          <PlayStoreBtns />
        </div>
      </div>
    </>
  );
};

export default CreatorEnglishSection1;
