import styles from './OpenToAllPage.module.scss';
import React from 'react';
import FooterSocialInfo from '../footerSocialInfo/FooterSocialInfo';
import { Footer, Header } from '../common';

const OpenToAllPage = () => {
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`header-container ${styles.headerContainer}`}>
          <Header />
        </div>
        <div className={`${styles.contentWrapper}`}>
          <div className={`${styles.welcomeContainer}`}>
            <div className={`${styles.welcomeMsg}`}>Welcoming All Genders to Join the Conversation!</div>
            <div loading="lazy" className={`${styles.welcomeIllustration}`} />
          </div>
          <div className={`${styles.contentContainer}`}>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.heading}`}>Arré Voice is now open to All!</div>
              <div className={`${styles.text}`}>
                Some amazing women creators across the country paved the way, and we’re now ready for all genders to
                experience the new wave of voices on Arré Voice. Everyone’s invited to listen, create, and connect!
              </div>
            </div>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.heading}`}>Women run the world, and this app too!</div>
              <div className={`${styles.text}`}>
                As a women-first app, our commitment to women's safety and growth as creators is unwavering. Our
                platform puts women first and does not tolerate any negative or unsafe behavior towards anyone.
              </div>
            </div>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.heading}`}>Join us in keeping this a happy, safe space!</div>
              <div className={`${styles.text}`}>
                We’re constantly monitoring the content on the app to make sure Arré Voice remains your happy place.
                Please report any content you find to be violating our community guidelines. Read them here. 
              </div>
            </div>
          </div>
          {/*footer */}
          <FooterSocialInfo />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OpenToAllPage;
