export const breakPoints = {
  phone: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
  LargeDesktop: 1920,
};

export const howToReelMobile =
  'https://newarrevoicecomwebsite.s3.ap-south-1.amazonaws.com/videos/howToReelMobileApple.mp4';

export const howToReelLaptop =
  'https://newarrevoicecomwebsite.s3.ap-south-1.amazonaws.com/videos/howToReelLaptopApple.mp4';

export const arreVoiceAppStoreUrl = 'https://apps.apple.com/in/app/arr%C3%A9-voice/id1615378103';
export const arreVoicePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.arre.voice';
export const appStoreSingularTrackingUrl = 'https://arre.link/6y6d4d9'; //ios
export const playStoreSingularTrackingUrl = 'https://arre.link/7u6d4z8'; //android

export const homeContainerOnScrollHandler = (e) => {
  if (window.innerWidth < breakPoints?.laptop) {
    const headerContainerDiv = document.getElementsByClassName('header-container')[0];
    if (e.deltaY < 0) {
      headerContainerDiv.style.top = '0';
    } else {
      headerContainerDiv.style.top = '-200px';
    }
  }
};
//
let _lastY;

export const homeContainerOnTouchStartHandler = (e) => {
  if (e.touches) _lastY = e.touches[0].clientY;
};

export const homeContainerOnTouchMoveHandler = (e) => {
  if (window.innerWidth < breakPoints?.laptop) {
    const headerContainerDiv = document.getElementsByClassName('header-container')[0];
    let currentY = e.touches ? e.touches[0].clientY : 0;
    if (currentY < _lastY) {
      headerContainerDiv.style.top = '-200px';
    } else {
      headerContainerDiv.style.top = '0';
    }
  }
};

export const handleAppInstallConversion = (redirectUrl) => {
  // Track a conversion event when a specific action occurs
  window.gtag('event', 'conversion', {
    send_to: 'G-TR6LE0L8XK',
  });
  window.gtag('event', 'conversion', {
    send_to: 'AW-10812075243/NneECIzkrokZEOvZzKMo',
  });
  window.fbq('trackCustom', 'conversion', { value: 1.0 });
  const res = new URLSearchParams(window.location.search);
  const searchParams = res?.size > 0 ? '?' + res.toString() : '';
  window.location.href = redirectUrl + searchParams;
};
