import { Footer, Header } from '../common';
import FooterSocialInfo from '../footerSocialInfo/FooterSocialInfo';
import styles from './CreatorEnglishPage.module.scss';
import React from 'react';
import CreatorEnglishSection1 from './creatorEnglishSection1/CreatorEnglishSection1';
import CreatorEnglishSection2 from './creatorEnglishSection2/CreatorEnglishSection2';
import CreatorEnglishSection3 from './creatorEnglishSection3/CreatorEnglishSection3';

const CreatorEnglishPage = () => {
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`header-container ${styles.headerContainer}`}>
          <Header />
        </div>
        <div className={`${styles.contentWrapper}`}>
          <div className={`${styles.section1Container}`}>
            <CreatorEnglishSection1 />
          </div>
          <div className={`${styles.section2Container}`}>
            <CreatorEnglishSection2 />
          </div>
          <div className={`${styles.section3Container}`}>
            <CreatorEnglishSection3 />
          </div>
          {/*footer */}
          <FooterSocialInfo />
        </div>
        <div className={`${styles.footerContainer}`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CreatorEnglishPage;
