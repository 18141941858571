import { PlayStoreBtns } from '../../common';
import styles from './CreatorTamilSection1.module.scss';
import React from 'react';

const CreatorTamilSection1 = () => {
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.textContainer}`}>
            <div className={`${styles.text1}`}>
            இந்தியாவின் முதல் women-first, short format audio app-{' '}
              <span className={`${styles.subtext1}`}>Arré Voice</span>.
            </div>
          </div>
          <div className={`${styles.text2}`}>இது நீங்களா?</div>
          <div className={`${styles.illustrationsContainer}`}>
            <div className={`${styles.illustrationContainer}`}>
              <div loading="lazy" className={`${styles.illustrationImg1}`} />
              <div className={`${styles.illText1}`}>மனம் விட்டு பேசுவீர்களா?</div>
            </div>
            <div className={`${styles.illustrationContainer2}`}>
              <div loading="lazy" className={`${styles.illustrationImg2}`} />
              <div className={`${styles.illText2}`}>ஜாலி-ஆ பாடுவீர்களா?</div>
            </div>
            <div className={`${styles.illustrationContainer}`}>
              <div loading="lazy" className={`${styles.illustrationImg3}`} />
              <div className={`${styles.illText3}`}>உங்க குரல் மூலமா டிஜிட்டல் தளத்தில் கால் பதிக்க விரும்புகிறீர்ளா?</div>
            </div>
          </div>
          <div className={`${styles.text3}`}>அப்போ...</div>
          <PlayStoreBtns />
        </div>
      </div>
    </>
  );
};

export default CreatorTamilSection1;
