import { PlayStoreBtns } from '../../common';
import styles from './CreatorTamilSection2.module.scss';
import React from 'react';

const CreatorTamilSection2 = () => {
  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.container1}`}>
            <div className={`${styles.arreVoiceLogoContainer}`}>
              <div className={`${styles.arreVoiceLogo}`}>
                <img alt="Arré Logo" src="/assets/logos/arreLogoDark.svg" width={'100%'} height={'100%'} />
              </div>
              <div className={`${styles.text1}`}>இது தான் உங்களுக்கான இடம்!</div>
            </div>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.text2}`}>உங்களை சுற்றியுள்ள அனைத்து தலைப்புகளை ஆராய்ந்து பதிவு செய்யுங்கள்</div>
              <div className={`${styles.text3}`}>
              செக்ஸ் & ரிலேஷன்ஷிப், செய்திகள், திரைப்படங்கள் மற்றும் பல.. 
              </div>
            </div>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.text2}`}>ஆகியவற்றையும் இன்னும் நிறைய தலைப்பு கொண்டு பேசுங்கள்...Short audio format-ல்</div>
              <div className={`${styles.text3}`}>
              உங்களை போன்ற மற்ற க்ரியேட்டர்ஸ்-ஐ கண்டு செய்திகள் பகிர்ந்து app-ன் முதன்மையான க்ரியேட்டர்-ஆக மாறுங்கள்.
              </div>
            </div>
          </div>
          <div className={`${styles.container2}`}>
            <PlayStoreBtns />
          </div>
        </div>
        <div loading="lazy" className={`${styles.bgIllustration1}`} />
        <div loading="lazy" className={`${styles.BgStar}`} />
        <div loading="lazy" className={`${styles.bgIllustration2}`} />
      </div>
    </>
  );
};

export default CreatorTamilSection2;
