import styles from './Footer.module.scss';

const instagramLogo = 'https://asset.arredigital.co/Arr%C3%A9+Voice/logos/instagramLogo.svg';
const linkedinLogo = 'https://asset.arredigital.co/Arr%C3%A9+Voice/logos/linkedinLogo.svg';
const youtubeLogo = 'https://asset.arredigital.co/Arr%C3%A9+Voice/logos/youtubeLogo.svg';

const Footer = () => {
  return (
    <>
      {/* footer */}
      <div className={`footer-container ${styles.footerContainer}`}>
        <div className={`${styles.footerContent}`}>
          <div className={`${styles.privacyContent}`}>
            <div className={`${styles.privacySection}`}>
              <div>
                <a
                  href="https://www.arre.co.in/terms-of-Use/"
                  target={'_blank'}
                  rel={'noreferrer'}
                  className="hover:underline"
                >
                  Terms of Use
                </a>
              </div>
              <div>
                <a
                  href="https://www.arre.co.in/arre-voice-privacy-policy/"
                  target={'_blank'}
                  rel={'noreferrer'}
                  className="hover:underline"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className={`${styles.privacySection}`}>
              <div>
                <a
                  // href="https://www.arre.co.in/self-regulation/"
                  href="https://arrevoice.notion.site/Self-Regulation-fde7fd3fd1a549ed8ce8c76e52b7f182"
                  target={'_blank'}
                  rel={'noreferrer'}
                  className="hover:underline"
                >
                  Self Regulation
                </a>
              </div>
              <div>
                <a href="/community-guidelines" target={'_self'} rel={'noreferrer'} className="hover:underline">
                  Community Guidelines
                </a>
              </div>
            </div>
          </div>
          <div className={`${styles.footerDivider}`}>
            <img alt="Footer Divider.img" src={'/assets/images/divider.svg'} width={'100%'} height={'100%'} />
          </div>
          <div className={`${styles.footerSocialIcons}`}>
            <a
              href="https://www.instagram.com/arre_voice/"
              target={'_blank'}
              rel={'noreferrer'}
              className={`${styles.footerSocialIcon}`}
            >
              <img loading="lazy" alt="Instagram Logo" src={instagramLogo} width={'100%'} height={'100%'} />
            </a>
            <a
              href="https://in.linkedin.com/company/arr%C3%A9-u-digital-content-pvt-ltd-"
              target={'_blank'}
              rel={'noreferrer'}
              className={`${styles.footerSocialIcon}`}
            >
              <img loading="lazy" alt="Linkedin Logo" src={linkedinLogo} width={'100%'} height={'100%'} />
            </a>
            <a
              href="https://www.youtube.com/channel/UC2O-N1R4x56XhndL4qqfKcw"
              target={'_blank'}
              rel={'noreferrer'}
              className={`${styles.footerSocialIcon}`}
            >
              <img loading="lazy" alt="Youtube Logo" src={youtubeLogo} width={'100%'} height={'100%'} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
