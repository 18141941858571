import {
  appStoreSingularTrackingUrl,
  handleAppInstallConversion,
  playStoreSingularTrackingUrl,
} from '../../../constants';
import styles from './Header.module.scss';
import React from 'react';

const Header = () => {
  return (
    <>
      <div className={`${styles.headerContainer}`}>
        <div className={`${styles.headerArreLogo}`}>
          <a href="/">
            <img alt="Arré Logo" src="/assets/logos/arreLogo.svg" width={'100%'} height={'100%'} />
          </a>
        </div>
        <div className={`${styles.headerSocialIconsContainer}`}>
          <div className={`${styles.headerSocialIcon} cursor-pointer`}>
            <div
              onClick={() => {
                handleAppInstallConversion(appStoreSingularTrackingUrl);
              }}
            >
              <img
                loading="lazy"
                alt="AppStoreBtn"
                src={'/assets/logos/appStoreBtnLg.svg'}
                width={'100%'}
                height={'100%'}
              />
            </div>
          </div>
          <div className={`${styles.headerSocialIcon} cursor-pointer`}>
            <div
              onClick={() => {
                handleAppInstallConversion(playStoreSingularTrackingUrl);
              }}
            >
              <img
                loading="lazy"
                alt="PlayStoreBtn"
                src={'/assets/logos/playStoreBtnLg.svg'}
                width={'100%'}
                height={'100%'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
