import styles from './ErrorPage.module.scss';
import React from 'react';
import { Footer, Header } from '../common';

const Page404 = () => {
  return (
    <>
      <div className={`${styles.page}`}>
        <div className={`header-container ${styles.headerContainer}`}>
          <Header />
        </div>
        <div className={`${styles.bodyContainer}`}>
          <div className="h-[100%] flex flex-col justify-center items-center">
            <div className={`${styles.oopsImage} pt-[20px]`}>
              <img src="/assets/images/oops.png" alt="oops" width={'100%'} height={'100%'} />
            </div>
            <div className={`${styles.errorImage}`}>
              <img src="/assets/images/404NotFound.png" alt="oops" width={'100%'} height={'100%'} />{' '}
            </div>
            <div className={`${styles.errorMsg}`}>It looks like that page went missing. </div>
          </div>
          <div className={`${styles.footer}`}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
