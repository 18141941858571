import styles from './CommunityGuidelinesPage.module.scss';
import React, { useState, useRef } from 'react';
import { Footer, Header } from '../common';
import FooterSocialInfo from '../footerSocialInfo/FooterSocialInfo';

const CommunityGuidelinesPage = () => {
  const [isSection1Visible, setIsSection1Visible] = useState(true);
  const [isSection2Visible, setIsSection2Visible] = useState(true);
  const sectionRef = useRef();
  //
  const scrollToSection = (element) => {
    sectionRef.current = element;
    sectionRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`header-container ${styles.headerContainer}`}>
          <Header />
        </div>
        <div className={`${styles.contentWrapper}`}>
          <div className={`${styles.welcomeContainer}`}>
            <div className={`${styles.welcomeMsg} ${styles.welcomeMsg1}`}>Arré Voice Community Guidelines</div>
            <div className={`${styles.welcomeMsg} ${styles.welcomeMsg2}`}>
              <div>Arré Voice</div>
              <div>Community Guidelines</div>
            </div>
            <div loading="lazy" className={`${styles.welcomeIllustration}`} />
          </div>
          <div className={`${styles.contentContainer}`}>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.overviewHeader}`}>Overview</div>
              <div className={`${styles.text}`}>
                Women run the world AND this app. We built Arré Voice to foster an inclusive community where people of
                all genders are invited to voice their views, talent, experiences and much more, all within the format
                of 30 Second Voicepods,  without fear of judgment, harassment, or discrimination. 
                <div>
                  We always encourage you to use your voice to comment, debate, and express your opinions, but not at
                  the expense of anyone else. We have laid out for you the following community guidelines/rules
                  (“Guidelines”) to make sure Arré Voice continues to remain a fun, positive, and happy place.
                </div>
              </div>
              <div className={`${styles.text}`}>
                You shall be solely responsible for the Content posted, shared, modified uploaded, transmitted, updated
                and hosted by you and it is your responsibility to ensure that your Content is in line with these
                Guidelines as well as the{' '}
                <a
                  className={`${styles.hyperLink}`}
                  href="https://www.arre.co.in/arre-voice-privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  className={`${styles.hyperLink}`}
                  href="https://www.arre.co.in/terms-of-Use/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{' '}
                etc.
                <div className={`${styles.text}`}>
                  These Guidelines apply to all users of Arré Voice and Arré Voice in its sole discretion may take any
                  action against content posted by users which does not comply with these Guidelines.
                </div>
              </div>
            </div>
            <div className={`${styles.heading}`}>
              THESE GUIDELINES ARE NOT EXHAUSTIVE, AND WE HOPE TO REFINE THEM FURTHER AS WE LEARN.
            </div>
            <div className={`${styles.text}`}>
              There are two key principles we have used to define these guidelines, given our unique position as a
              women-first platform:
            </div>
            {/* section1 */}
            <div className={`${styles.sectionContainer}`}>
              <div className={`${styles.heading}`}>
                I. Context Is{' '}
                <span style={{ textDecoration: 'line-through', color: 'black' }}>
                  <span style={{ color: '#EE8C34' }}>King</span>
                </span>
                . Queen.
              </div>
              <img
                onClick={() => {
                  setIsSection1Visible(!isSection1Visible);
                }}
                className={`${styles.arrow}`}
                src={`/assets/images/${isSection1Visible ? 'upwardArrowGreen.svg' : 'downwardArrowGreen.svg'}`}
                alt="arrow"
              ></img>
            </div>
            {isSection1Visible && (
              <>
                <div loading="lazy" className={`${styles.talkingGirlIllustration}`} />
                <div className={`${styles.text}`}>
                  We are building ourselves as a place where everyone can express themselves freely. While some themes,
                  such as sexual wellness, may be considered taboo in mainstream media, we recognize the importance of
                  having open and honest conversations about them. However, we also understand the sensitivity around
                  these topics and the potential for them to be used in harmful ways. Therefore, we will approach each
                  instance on a case-by-case basis and carefully consider the context before reaching any conclusions.
                </div>
              </>
            )}
            {/* section2 */}
            <div className={`${styles.sectionContainer}`}>
              <div className={`${styles.heading}`}>II. Law Trumps Everything</div>
              <img
                onClick={() => {
                  setIsSection2Visible(!isSection2Visible);
                }}
                className={`${styles.arrow}`}
                src={`/assets/images/${isSection2Visible ? 'upwardArrowGreen.svg' : 'downwardArrowGreen.svg'}`}
                alt="arrow"
              ></img>
            </div>
            {isSection2Visible && (
              <>
                <div loading="lazy" className={`${styles.safetyIllustration}`} />
                <div className={`${styles.text}`}>
                  We are all for individual freedom but not at the cost of violating the laws of the land. We believe in
                  maintaining a safe and ethical community and will not tolerate any content related to illegal
                  activities, including but not limited to drugs, arms, child abuse, pornography, hate speech – you can
                  see a detailed list below. We will take immediate action against it and even involve official
                  authorities when necessary. We encourage all members to report any concerning content they come across
                  and join us in creating a positive and responsible community.{' '}
                </div>
              </>
            )}
            <div className={`${styles.text}`}>
              Now let us get down to the specifics of what works, what may not & what definitely won’t:
            </div>
            <ol style={{ listStyleType: 'decimal' }} className={`${styles.listOfSections}`}>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section1'));
                }}
                className={`${styles.sectionLink}`}
              >
                Graphic & sexual content is no-go!
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section2'));
                }}
                className={`${styles.sectionLink}`}
              >
                No Room for cyber bullies!
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section3'));
                }}
                className={`${styles.sectionLink}`}
              >
                Private Info is off-limits!
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section4'));
                }}
                className={`${styles.sectionLink}`}
              >
                The no-nonsense guide to keeping it legal
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section5'));
                }}
                className={`${styles.sectionLink}`}
              >
                NO GUTS, NO GORY. We mean it!
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section6'));
                }}
                className={`${styles.sectionLink}`}
              >
                Haters gonna hate…Well, not here!
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section7'));
                }}
                className={`${styles.sectionLink}`}
              >
                Keep calm and be a mindful poster.
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section8'));
                }}
                className={`${styles.sectionLink}`}
              >
                Don't be a rumor mill!
              </li>
              <li
                onClick={() => {
                  scrollToSection(document.getElementById('section9'));
                }}
                className={`${styles.sectionLink}`}
              >
                Keep it original!
              </li>
            </ol>
            <div id="section1" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>1.</div>
              <div>Graphic & sexual content is no-go! </div>
            </div>
            <div className={`${styles.text}`}>Do not post graphic, obscene and/or sexual content</div>
            <div className={`${styles.text}`}>
              The idea of building an app that puts women-first and plans to be open to all is to let them talk about
              things they otherwise can’t, we encourage our users to talk about sexual education but what we will not
              tolerate are:
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                Any direct or indirect means of soliciting sex, which goes on to include prostitution or escort
                services. We however welcome women from these professions to come and be a part of the app
              </li>
              <li>
                Any post (called ‘pod’) meant to provide sexual gratification or simulate sexual interaction/
                pornographic content.
              </li>
              <li>
                Any visual representation including in digital format display or description of private organs (nipples
                and vagina for women, penis for men) for gratification/full or partial nudity.
              </li>
              <div>
                However, it may be permitted in the context of educating about birth giving, after-birth moments, child
                nourishment or if there are medical or health context situations like gender confirmation surgery,
                examination for cancer or disease prevention/assessment.
              </div>
              <li>
                Visual or audio material that is offensive to a real person within or outside the app, shared without
                their consent
              </li>
              <li>
                Any report of intent or act of rape, bestiality, zoophilia or paedophilia and/or any glorification of
                clinically recognised fetishes discovered now or in future.
              </li>
              <li>
                We are all about women’s safety and act proportionately against cases of sexual advances,
                promoting/threatening sexual assault, revealing the identity of sexual assault survivors, sexual abuse,
                sexual exploitation, sexual trafficking
              </li>
              <li>Content sexualising minors in any way or subjecting them to any form of sexual abuse </li>
            </ol>
            <div id="section2" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>2.</div>
              <div>No Room for cyber bullies!</div>
            </div>
            <div className={`${styles.text}`}>
              Arré Voice does not allow users to engage in bullying or harassment on the platform. This includes but is
              not limited to sharing, posting defamatory or libellous content or sending threatening messages or insults
              to another Arré Voice user. We want our users to feel secure in this Arré Voice universe.
            </div>
            <div className={`${styles.text}`}>
              If you suffer or witness any user on the app undergo the following, immediately report to us:
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                Impersonation, please do not portray yourself to be someone you are not, it is actually a crime. Do not
                say you are above 17+ if you are not. Do share your real identity, we are trying to create a safe space
                for you and all our users. However, parody accounts, fan accounts and other accounts that do not mislead
                other users of Arré Voice are permitted.
              </li>
              <li>
                Refrain from use of any language to your fellow community members which could be construed as
                objectionable, abusive, hurtful, having racial cultural or societal overtones, slangs, expletives, name
                calling, personal attacks, comments on physical and mental attributes, doing so would be deemed as
                harassment.
              </li>
              <li>
                It is unacceptable to share content rooted in caste, race, religion, disabilities or gender
                discrimination with intention to insult or spread negativity
              </li>
              <li>
                Using technology to harass via creating multiple accounts, fake accounts, or derogatory user handles
              </li>
              <li>
                Any action taken with the aim of misrepresentation through phishing, cloning, doxing, or catfishing is
                prohibited.
              </li>
              <li>Any attempt to hack, clone or damage the product software</li>
              <li>
                Gaining unauthorized access to accounts, profiles, or user data other than your own without permission
                from the account owner or through deceptive means is wrong.
              </li>
              <li>
                Prolonged name-calling, abusive language, repeated harassment, malicious insults, ad hominem speech,
                using the app to shame, anger or scare any individual will not be accepted and reported as bullying
              </li>
              <li>
                Use of self-bots or user-bots to manipulate traffic or increase app credentials is not acceptable.
              </li>
              <li>
                Spam others on the app to deceive, mislead or increase engagement from others, via incorrect means
              </li>
            </ol>
            <div id={'section3'} className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>3.</div>
              <div>Private Info is off-limits!</div>
            </div>
            <div className={`${styles.text}`}>
              Do not post or threaten to post any content revealing any personally identifiable information
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                Don’t share any identifiable information about yourself or others, especially Identification related
                cards or numbers
              </li>
              <li>
                Do not reveal any personal information about anybody, including but not limited to their IDs or
                registration details
              </li>
              <li>
                While our community is friendly, it will be wise for you not to reveal any confidential residential,
                medical and financial information that can put you at risk
              </li>
              <li>
                Refrain from sharing any confidential information about a company or any other organisation, unless you
                have their explicit consent
              </li>
            </ol>
            <div id="section4" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>4.</div>
              <div>The no-nonsense guide to keeping it legal</div>
            </div>
            <div className={`${styles.text}`}>
              At Arré Voice, we strive to foster a safe and respectful community for all users. To achieve this goal we
              do not permit any unlawful behaviour or illegal activities on the platform and we are committed to taking
              prompt action to remove content that promotes, justifies, incites, or encourages others to engage in any
              criminal activities.
            </div>
            <div className={`${styles.text}`}>
              The following list highlights some of the criminal activities as per the applicable laws that are strictly
              prohibited on our platform. However, this list is not exhaustive, and we reserve the right to take action
              against any behavior that we deem inappropriate or illegal:
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Physical harm: </span>
                Killing, Rape, acid attack, assault, battery, kidnapping, use/trade of firearms and ammunition or lethal
                enhancements, threat
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Bullying and Harassment:</span>
                Dowry harassment, stalking, Indecent comments, sexual harassment
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Drugs:</span>
                Selling or providing information about selling and procuring substances that are illegal as per Indian
                laws, buy, sell or trade alcohol or tobacco
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Hazardous goods and materials: </span>
                Carrying or dealing in them, including potentially dangerous cosmetic procedures is a crime
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Human exploitation:</span>
                Slavery, trafficking, bonded labor, forced prostitution, solicitation
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Wildlife:</span>
                Poaching, hunting or illegal trade of wildlife. Any glorification of the same will not be entertained
              </li>
              <li>
                You must not post content that incites other users to commit an offence or prevents the investigation of
                any offence, nor engage in any form of recruitment or grouping for organized crime or illegal
                activities.
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>National Assets: </span>
                If you disrespect any of the national assets including but not limited to the National flag, names of
                prominent figures of the country as outlined by the Government of India, necessary action will be taken
                against such posts and messages.
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Prohibited games:</span>
                You must not use the platform to offer access to lotteries, gambling, or any other prohibited games.
              </li>
              <li>
                <span className={`${styles.text} ${styles.text2}`}>Religiously Offensive Content:</span>
                Users must refrain from publishing any content that may hurt the sentiments of others or insult their
                religion, customs, or beliefs. Additionally, any actions that involve abuse of religious deities,
                prophets, figureheads, reincarnations, leaders, or desecration of religious symbols or emblems with the
                intent to cause disharmony may be considered as religiously offensive content. Our platform values
                diversity, inclusivity, and harmony, and any violation of these principles will result in appropriate
                action
              </li>
              <li>Co-ordinating harm physical or mental by disclosing confidential matters </li>
              <li>
                Supporting, promoting entities that engage in serious offline harm including but not limited to
                terrorist organisations, extremist organisations or individuals. In particular, refrain from posting
                content to incite terrorism, secession, acts of violence against person or property or which threatens
                the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign
                nations, or insults another nation.
              </li>
              <li>
                Any form of committing/ promoting fraudulent activity, for instance scams, financial frauds, money
                laundering.
              </li>
            </ol>
            <div id="section5" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>5.</div>
              <div>NO GUTS, NO GORY. We mean it!</div>
            </div>
            <div className={`${styles.text}`}>
              Do not post content that threatens, depicts or glorifies violence, or encourages acts of violence.{' '}
            </div>
            <div className={`${styles.text}`}>
              While we recognise that violence is an unfortunate reality in the world, such content must not be shared
              on the app. We believe that creating a safe and respectful community is of utmost importance, and sharing
              such graphic and potentially triggering content goes against this principle. We encourage our users to use
              discretion and sensitivity when posting content on the app to maintain a positive and uplifting
              environment for all members of the community.
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                Please do not share content involving anything shocking, disgusting or gruesome or if it promotes,
                normalizes, direct or indirect physical harm or glorifies violence or suffering,{' '}
              </li>
              <li>
                Distressing events like violent deaths, dismembering, charring of human bodies, mass murders, or
                real-world physical violence happen, but it should not be shared on the app
              </li>
              <li>
                Please do not share Anything that shows verbal, mental or physical abuse being committed on anyone,
                especially minors
              </li>
              <li>
                Please do not share any form of Graphic content or the act of releasing urine, feces, vomit or semen –
                don’t show them.
              </li>
              <li>Please do not share any form of Animal cruelty, gore, or slaughter of any form</li>
            </ol>
            <div id="section6" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>6.</div>
              <div>Haters gonna hate…Well, not here!</div>
            </div>
            <div className={`${styles.text}`}>Do not post hateful or discriminatory content</div>
            <div className={`${styles.text}`}>
              We encourage healthy and constructive conversations on our platform, and we understand that valid
              disagreements can arise. However, we do not tolerate any form of hateful or personal attacks, including ad
              hominem speech.
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                Intimidation, targeting or demeaning or defaming an individual, group, association or organization shall
                not be permitted.
              </li>
              <li>
                Any speech, comments or sharing of theoretical views of content or ideologies that build hate or cause
                outrage or intentionally insults and provokes another to disrupt public peace or commit any offence will
                not be permitted
              </li>
              <li>Any form of discrimination or violent behavior shall not be permitted</li>
              <div className={`${styles.text} mt-2`}>
                However, please ensure that your content aimed at raising awareness within our community is not
                mistakenly flagged for violating our guidelines, it is important to clearly state your intentions when
                sharing such content.
              </div>
            </ol>
            <div id="section7" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>7.</div>
              <div>Keep calm and be a mindful poster.</div>
            </div>
            <div className={`${styles.text}`}>
              We go through various mental stages in life but if there is anything that causes any form of suicide,
              euthanasia/assisted suicide, eating disorders or self-harm, we expect the people in our community to not
              amplify it. This includes but is not limited to sharing real life occurrences of any form of physical
              self-harm, psychological disorders.
            </div>
            <div className={`${styles.text}`}>
              We do encourage our members to help others suffering from the same, with useful learning tools and words
              of encouragement. Please see the information relating to helplines available in the public domain. You may
              also connect with many other organisations in your local areas.
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                Don’t put anything that can trigger/promote people to harm themselves - not everyone is at the same
                mental health fitness. Display or glorification of such content will be more harmful
              </li>
              <li>
                Do not provide instructions on methods of committing harm, injury to oneself, including methods of
                suicide
              </li>
              <li>Don’t use any negative commentary towards victims and survivors of trauma</li>
            </ol>
            <div id="section8" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>8.</div>
              <div>Don't be a rumor mill!</div>
            </div>
            <div className={`${styles.text}`}>
              Be real, be authentic - don't put anything that is false, hoax, misleading or a threat to security
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>Anything likely to directly contribute to a risk of imminent violence or physical harm to people</li>
              <li>
                Avoid spreading rumours or anything that can cause people to panic. This includes but is not limited to
                information on weather, medical or health situation, financial misinformation and political ongoings
              </li>
              <li>Any person’s reputation should not be harmed using defamatory or libelous content.</li>
              <li>
                We strictly prohibit the hosting, displaying, uploading, modifying, publishing, transmitting, storing,
                updating, or sharing of any information related to the activity of the Central Government that has been
                flagged by the fact-checking unit as fake, false, or misleading.
              </li>
            </ol>
            <div id="section9" className={`${styles.heading2} flex flex-row space-x-4`}>
              <div>9.</div>
              <div>Keep it original!</div>
            </div>
            <div className={`${styles.text}`}>Do not violate the Intellectual Property Rights of others.</div>
            <div className={`${styles.text}`}>
              We are a platform that encourages people to be their true selves and share their own, authentic content.
              If people indulge in stealing ideas and content from each other, it is at odds /against the way we
              envision this vibrant community. Respect others and their original content, just as you would yours.
            </div>
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.orderedLists} ${styles.text}`}>
              <li>
                Don't use or upload copyright-protected literary, dramatic or choreographic work without the explicit
                permission of the copyright holder.
              </li>
              <li>
                Also don't use anything that has exclusive rights attached to it, unless the trademark owner allows you
                the use. Trademarks could refer to a brand name like “Arré” or “Arré Voice”. We hold an exclusive right
                over it and it cannot be used to sell other products, unless we get into an official collaboration and/
                or any other form of use which is not permissible under the law and without obtaining prior consent.
              </li>
            </ol>
          </div>
          {/*footer */}
          <FooterSocialInfo />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CommunityGuidelinesPage;
