import { PlayStoreBtns } from '../../common';
import styles from './CreatorEnglishSection3.module.scss';
import React from 'react';

const CreatorEnglishSection3 = () => {
  const tipsContainerPoints = [
    'Deeply personal stories on Sex, Relationship, Intimacy',
    'Mental health stories, Hacks, Positive energies, Healing',
    'Pop culture Themes - Movies, Gossips, Festivals, Celebrities etc.',
    'And anything that pops into your head.',
  ];
  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.titleContainer}`}>
          <div className={`${styles.titleText1}`}>How to get started?</div>
          <div className={`${styles.titleText2}`}>
            Learn the basics of what Arré Voice is and how to create content on this platform
          </div>
        </div>
        <div className={`${styles.bodyContainer}`}>
          <div className={`${styles.container1}`}>
            <div className={`${styles.subContainer1}`}>
              <div className={`${styles.subContainer1TextContainer}`}>
                <div className={`${styles.subContainer1Text1}`}>Voicepods</div>
                <div className={`${styles.subContainer1Text2}`}>
                  Posts take a new form on Arré Voice in the form of 30 second audio clips called voicepods
                </div>
              </div>
              <div loading="lazy" className={`${styles.subContainer1Img}`} />
            </div>
            <div className={`${styles.subContainer2}`}>
              <div loading="lazy" className={`${styles.subContainer2Img}`} />
              <div className={`${styles.subContainer2TextContainer}`}>
                <div className={`${styles.subContainer2Text1}`}>Creator Studio</div>
                <div className={`${styles.subContainer2Text2}`}>
                  This is where you will start your recording journey, think of it as a recording studio in your own
                  pocket
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.textContainer1}`}>Let me show you how to create</div>
          <div loading="lazy" className={`${styles.container2}`} />
          <div className={`${styles.textContainer2}`}>
            And <span className={`${styles.textContainer2SubText}`}>Post! </span>That's it. Easy as pie.
          </div>
          <PlayStoreBtns />
          <div loading="lazy" className={`${styles.divider1}`} />
          <div className={`${styles.container3}`}>
            <div className={`${styles.subContainer3}`}>
              <div className={`${styles.subContainer3TextContainer}`}>
                <div className={`${styles.subContainer3Text1}`}>Who can become a Creator?</div>
                <ol style={{ listStyleType: 'initial' }} className={`${styles.subContainer3Points}`}>
                  <li>
                    You can talk about your deeply personal experience about relationships, mental health, personal
                    experiences etc.
                  </li>
                  <li>Your voice can connect with people</li>
                  <li>You have 60 seconds to spare</li>
                </ol>
                <div className={`${styles.subContainer3Text3}`}>Then you are a perfect fit!!</div>
              </div>
              <div loading="lazy" className={`${styles.cont3bgIllustration}`} />
              <div loading="lazy" className={`${styles.cont3BgStar}`} />
            </div>
            <div loading="lazy" className={`${styles.subContainer4}`} />
          </div>
          <div loading="lazy" className={`${styles.divider2}`} />
          <div className={`${styles.container4}`}>
            <div className={`${styles.textContainer3}`}>
              <div>What are the areas</div>
              <div>where you can create?</div>
            </div>
            <div className={`${styles.subContainer5}`}>
              <div loading="lazy" className={`${styles.cont4bgIllustration}`} />
              <div className={`${styles.tipsContainer}`}>
                {tipsContainerPoints.map((point, index) => (
                  <div key={index + Math.random()} className={`${styles.pointContainer}`}>
                    <img alt="Voice Icon" src="/assets/images/ce_s3_voiceImg.svg" width={28} height={28} />
                    <div className={`${styles.pointText}`}>{point}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`${styles.container5}`}>
            <div className={`${styles.subContainer6}`}>
              <div className={`${styles.subContainer3TextContainer}`}>
                <div className={`${styles.subContainer3Text1}`}>
                  <div>Speak Your Mind,</div>
                  <div>Whenever, Wherever.</div>
                </div>
                <ol style={{ listStyleType: 'initial' }} className={`${styles.subContainer3Points}`}>
                  <li>No need to put your face in front of a camera</li>
                  <li>No need to invest in expensive equipment / shoots</li>
                  <li>Just tap the record button and get started</li>
                </ol>
              </div>
              <div loading="lazy" className={`${styles.subCont6BgIllustration}`} />
              <div loading="lazy" className={`${styles.subCont6BgStar}`} />
            </div>
            <div className={`${styles.subContainer7}`}>
              <div className={`${styles.subContainer3TextContainer}`}>
                <div className={`${styles.subContainer3Text1}`}>Start Monetising Early</div>
                <ol style={{ listStyleType: 'initial' }} className={`${styles.subContainer3Points}`}>
                  <li>Discover Brands and Deals</li>
                  <li>Build your Following</li>
                  <li>Earn by creating content from your home</li>
                </ol>
              </div>
              <div loading="lazy" className={`${styles.subCont7BgIllustration}`} />
              <div loading="lazy" className={`${styles.subCont7BgStar}`} />
            </div>
          </div>
          <div className={`${styles.bottomPlayStoreBtns}`}>
            <PlayStoreBtns />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorEnglishSection3;
