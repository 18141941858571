import React, { useEffect, useState } from 'react';
import styles from './Section1Screen.module.scss';

const Section1Screen = ({ deviceInfo }) => {
  const [bgImage, setBgImage] = useState('/assets/images/section1Illustration.svg');
  //
  useEffect(() => {
    if (deviceInfo?.isMobile) {
      setBgImage('/assets/images/section1IllustrationMobile.svg');
    } else {
      setBgImage('/assets/images/section1Illustration.svg');
    }
  }, [deviceInfo]);
  //
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contentContainer}`}>
        <div className={`${styles.titleContainer}`}>
          <div className={`${styles.titleText1}`}>Welcome to a world of</div>
          <div className={`${styles.titleText2}`}>Voice!</div>
        </div>
        <img
          loading="lazy"
          className={`${styles.section1FDesign}`}
          src={'/assets/images/section1FDesign.svg'}
          alt="welcomeFDesign Img"
        ></img>
        {/* <div className={`${styles.lottiePlayer}`}>
          <lottie-player src="/assets/others/section1Lottie2.json" background="transparent" speed="1" loop autoplay />
        </div> */}
        <div className={`${styles.bgImage}`}>
          <img loading="lazy" src={bgImage} alt="Illustration" width={'100%'} height={'100%'}></img>
        </div>
        <div className={`${styles.bodyContainer}`}>
          <div className={`${styles.textContainer}`}>
            <div className={`${styles.bodyText1}`}>
              Introducing India’s only women-first, short format audio social app -{' '}
              <span className={`${styles.bodyText2}`}>Arré Voice</span>.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1Screen;
