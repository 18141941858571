import React, { useEffect, useState } from 'react';
import styles from './Section4Screen.module.scss';

const Section4Screen = (props) => {
  const { deviceInfo } = props;
  const [bgImage, setBgImage] = useState('/assets/images/languageIllustrationLargeClip.png');
  //
  useEffect(() => {
    if (deviceInfo?.isMobile) {
      setBgImage('/assets/images/languageIllustrationSmall.png');
    } else {
      setBgImage('/assets/images/languageIllustrationLargeClip.png');
    }
  }, [deviceInfo]);
  //
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contentContainer}`}>
        <div className={`${styles.titleContainer}`}>
          <div className={`${styles.titleText}`}>Create in any language you speak.</div>
        </div>
        <div className={`${styles.tagsContainer}`}>
          <div className={`${styles.tagsContent}`}>
            <div className={`${styles.tag} ${styles.tag1}`}>ನಮಸ್ಕಾರ!</div>
            <div className={`${styles.tag} ${styles.tag2}`}>नमस्ते!</div>
            <div className={`${styles.tag} ${styles.tag3}`}>Hello!</div>
            <div className={`${styles.tag} ${styles.tag4}`}>হ্যালো!!</div>
            <div className={`${styles.tag} ${styles.tag5}`}>નમસ્તે!</div>
            <div className={`${styles.tag} ${styles.tag6}`}>வணக்கம்!</div>
          </div>
        </div>
        <img
          loading="lazy"
          className={`${styles.languageFDesignImg}`}
          src={'/assets/images/languageFDesign.png'}
          alt="languageFDesign Img"
        ></img>
        <div className={`${styles.backgroundImage}`}>
          <img loading="lazy" className="" src={bgImage} alt="Become a Creator in 30"></img>
        </div>
        <div className={`${styles.bodyContainer}`}>
          <div className={`${styles.textContainer}`}>
            <div>
              Hindi, English, Tamil, Telugu and Malyalam are amongst the languages that are live on{' '}
              <span className={`${styles.textBold}`}>Arré Voice.</span>
            </div>
            <div>More languages to come !</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4Screen;
