import React, { useEffect, useState } from 'react';
import styles from './Section6Screen.module.scss';
import {
  appStoreSingularTrackingUrl,
  handleAppInstallConversion,
  playStoreSingularTrackingUrl,
} from '../../../constants';

const Section6Screen = (props) => {
  const { deviceInfo } = props;
  const [playStoreBtnImage, setPlayStoreBtnImage] = useState('/assets/logos/playStoreBtnLg.svg');
  const [appStoreBtnImage, setAppStoreBtnImage] = useState('/assets/logos/appStoreBtnLg.svg');
  //
  useEffect(() => {
    if (deviceInfo?.isMobile) {
      setPlayStoreBtnImage('/assets/logos/playStoreBtnSm.svg');
      setAppStoreBtnImage('/assets/logos/appStoreBtnSm.svg');
    } else if (deviceInfo?.isTablet) {
      setPlayStoreBtnImage('/assets/logos/playStoreBtnMd.svg');
      setAppStoreBtnImage('/assets/logos/appStoreBtnMd.svg');
    } else {
      setPlayStoreBtnImage('/assets/logos/playStoreBtnLg.svg');
      setAppStoreBtnImage('/assets/logos/appStoreBtnLg.svg');
    }
  }, [deviceInfo]);
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.titleContainer}`}>
            <div className={`${styles.titleText}`}>
              <div>Your Voice,</div>
              <div>Fun and Unfiltered</div>
            </div>
          </div>
          <div loading="lazy" className={`${styles.arreVoiceMobileIllustration}`} />
          <div className={`${styles.bodyContainer}`}>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.text1}`}>
                It’s time to <span className={`${styles.text1Color}`}>be heard!</span>
              </div>
              <div>
                <div>The world of voice awaits you. Download the app on your mobile devices now!</div>
              </div>
            </div>
            <div className={`${styles.socialIconsContainer}`}>
              <div className={`${styles.socialIcon} cursor-pointer`}>
                <div
                  onClick={() => {
                    handleAppInstallConversion(appStoreSingularTrackingUrl);
                  }}
                >
                  <img loading="lazy" alt="AppStoreBtn" src={appStoreBtnImage} width={'100%'} height={'100%'} />
                </div>
              </div>
              <div className={`${styles.socialIcon} cursor-pointer`}>
                <div
                  onClick={() => {
                    handleAppInstallConversion(playStoreSingularTrackingUrl);
                  }}
                >
                  <img loading="lazy" alt="PlayStoreBtn" src={playStoreBtnImage} width={'100%'} height={'100%'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section6Screen;
