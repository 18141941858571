import React, { useState } from 'react';
import styles from './HomeScreen.module.scss';
import Section2Screen from './section2/Section2Screen';
import {
  breakPoints,
  homeContainerOnScrollHandler,
  homeContainerOnTouchMoveHandler,
  homeContainerOnTouchStartHandler,
} from '../../constants';
import Section4Screen from './section4/Section4Screen';
import Section5Screen from './section5/Section5Screen';
import Section6Screen from './section6/Section6Screen';
import Section1Screen from './section1/Section1Screen';
import Section3NewScreen from './section3New/Section3NewScreen';
import { Footer, Header } from '../common';

const HomeScreen = () => {
  const innerWidth = window.innerWidth;
  const _deviceInfo = {
    screen: { width: window.innerWidth, height: window.innerHeight },
    isMobile: innerWidth <= breakPoints?.phone,
    isTablet: innerWidth >= breakPoints?.tablet && innerWidth < breakPoints?.laptop,
    isLaptop: innerWidth >= breakPoints?.laptop && innerWidth < breakPoints?.desktop,
    isDesktop: innerWidth >= breakPoints?.desktop,
  };
  const [deviceInfo] = useState(_deviceInfo);

  // select the favicon
  const faviconEl = document.querySelector('link[rel="icon"]');
  // watch for changes
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  mediaQuery.addEventListener('change', themeChange);

  // listener
  function themeChange(event) {
    faviconEl.setAttribute(
      'href',
      'https://arre-public-assets.s3.ap-south-1.amazonaws.com/Arr%C3%A9+Voice/favicon.ico'
    );
  }
  //
  return (
    <>
      <div
        className={`home-container ${styles.homeContainer} overflow-y-scroll`}
        onWheel={homeContainerOnScrollHandler}
        onTouchStart={homeContainerOnTouchStartHandler}
        onTouchMove={homeContainerOnTouchMoveHandler}
      >
        {/* header */}
        <div className={`header-container ${styles.headerContainer}`}>
          <Header />
        </div>
        {/* body */}
        <div className={`${styles.section1Container}`}>
          <Section1Screen deviceInfo={deviceInfo} />
        </div>
        <div>
          <img
            loading="lazy"
            className={`${styles.section1Footer}`}
            src={'/assets/images/section1Footer.svg'}
            alt="section1Footer Img"
          ></img>
        </div>
        <div className={`${styles.sectionContainer} ${styles.section2Container}`}>
          <Section2Screen deviceInfo={deviceInfo} />
        </div>
        <div className={`${styles.section3NewContainer}`}>
          <Section3NewScreen deviceInfo={deviceInfo} />
        </div>
        <div className={`${styles.sectionContainer} ${styles.section4Container}`}>
          <Section4Screen deviceInfo={deviceInfo} />
        </div>
        <div className={`${styles.sectionContainer} ${styles.section5Container}`}>
          <Section5Screen deviceInfo={deviceInfo} />
        </div>
        <div className={`section6Container ${styles.sectionContainer} ${styles.section6Container}`}>
          <Section6Screen deviceInfo={deviceInfo} />
        </div>
        {/* footer */}
        <Footer />
      </div>
    </>
  );
};

export default HomeScreen;
