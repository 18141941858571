import React, { useEffect, useState } from 'react';
import styles from './Section2Screen.module.scss';

const Section2Screen = (props) => {
  const { deviceInfo } = props;
  const [bgImage, setBgImage] = useState('/assets/images/section2image.svg');
  //
  useEffect(() => {
    if (deviceInfo?.screen.width < 545) {
      setBgImage('/assets/images/section2imageMobile.svg');
    } else if (deviceInfo?.screen.width >= 545 && deviceInfo?.screen.width < 605) {
      setBgImage('/assets/images/section2IllustrationMedium.svg');
    } else {
      setBgImage('/assets/images/section2image.svg');
    }
  }, [deviceInfo]);
  //
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contentContainer}`}>
        <div className={`${styles.titleContainer}`}>
          Become a creator <span className={`${styles.textContainer}`}>in 30 seconds</span>
        </div>
        <div className={`${styles.lottiePlayer}`}>
          <lottie-player src="/assets/others/Section2Lottie.json" background="transparent" speed="1" loop autoplay />
        </div>
        <div className={`${styles.backgroundImageContainer}`}>
          <img
            loading="lazy"
            src={bgImage}
            className={`${styles.backgroundImage}`}
            alt="Become a Creator in 30"
            width={'100%'}
            height={'100%'}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Section2Screen;
