import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/styles.scss';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import HomeScreen from './components/home/HomeScreen';
import OpenToAllPage from './components/openToAll/OpenToAllPage';
import CommunityGuidelinesPage from './components/communityGuidelines/CommunityGuidelinesPage';
import Page404 from './components/errorPages/Page404';
import Page500 from './components/errorPages/Page500';
import CreatorEnglishPage from './components/creatorEnglish/CreatorEnglishPage';
import CreatorTamilPage from './components/creatorTamil/CreatorTamilPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeScreen />,
  },
  {
    path: '/opentoall',
    element: <OpenToAllPage />,
  },
  {
    path: '/community-guidelines',
    element: <CommunityGuidelinesPage />,
  },
  {
    path: '/creator',
    element: <CreatorEnglishPage />,
  },
  {
    path: '/tamil-creator',
    element: <CreatorTamilPage />,
  },
  {
    path: '/404',
    element: <Page404 />,
  },
  {
    path: '/500',
    element: <Page500 />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

root.render(<RouterProvider router={router} />);
