import React from 'react';
import styles from './Section3NewScreen.module.scss';
import {
  appStoreSingularTrackingUrl,
  handleAppInstallConversion,
  playStoreSingularTrackingUrl,
} from '../../../constants';

const Section3NewScreen = () => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contentContainer}`}>
        <div className={`${styles.titleContainer}`}>
          <div className={`${styles.titleText}`}>Arré Voice is now open to All!</div>
        </div>
        <img
          loading="lazy"
          className={`${styles.fDesignImg}`}
          src={'/assets/images/runFDesign.png'}
          alt="runFDesign Img"
        ></img>
        <div className={`${styles.runIllustration}`}></div>
        <div className={`${styles.bodyContainer}`}>
          <div className={`${styles.textContainer}`}>
            <div>
              Some amazing women creators across the country paved the way, and we’re now ready for all genders to
              experience the new wave of voices on Arré Voice.
            </div>
            <div className={`${styles.text2}`}>Everyone’s invited to listen, create, and connect!</div>
          </div>
          <a className={`${styles.learnMoreContainer}`} href="/opentoall" rel={'noreferrer'} target={'_self'}>
            <div className={`${styles.learnMoreText}`}> Learn more</div>
            <img
              loading="lazy"
              className={`${styles.arrowRight}`}
              src={'/assets/images/arrowRight.svg'}
              alt="arrowRight Img"
            ></img>
          </a>
          <div className={`${styles.socialIconsContainer}`}>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleAppInstallConversion(appStoreSingularTrackingUrl);
              }}
            >
              <div className={`${styles.appStoreBtnImage}`} />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleAppInstallConversion(playStoreSingularTrackingUrl);
              }}
            >
              <div className={`${styles.playStoreBtnImage}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3NewScreen;
