import { PlayStoreBtns } from '../../common';
import styles from './CreatorEnglishSection2.module.scss';
import React from 'react';

const CreatorEnglishSection2 = () => {
  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.container1}`}>
            <div className={`${styles.arreVoiceLogoContainer}`}>
              <div className={`${styles.arreVoiceLogo}`}>
                <img alt="Arré Logo" src="/assets/logos/arreLogoDark.svg" width={'100%'} height={'100%'} />
              </div>
              <div className={`${styles.text1}`}>is the right place for you</div>
            </div>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.text2}`}>Explore and speak about topics from content around</div>
              <div className={`${styles.text3}`}>
                Sex & Relationships, Self Help, Mental Wellness, News, Movies, Gossips and much more...
              </div>
            </div>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.text2}`}>Meet fellow new age creators who specialize in short audio.</div>
              <div className={`${styles.text3}`}>
                Socialize with people who have similar creator ambitions and grow together.
              </div>
            </div>
          </div>
          <div className={`${styles.container2}`}>
            <PlayStoreBtns />
          </div>
        </div>
        <div loading="lazy" className={`${styles.bgIllustration1}`} />
        <div loading="lazy" className={`${styles.BgStar}`} />
        <div loading="lazy" className={`${styles.bgIllustration2}`} />
      </div>
    </>
  );
};

export default CreatorEnglishSection2;
