import { Footer, Header } from '../common';
import styles from './ErrorPage.module.scss';
import React from 'react';

const Page500 = () => {
  return (
    <>
      <div className={`${styles.page}`}>
        <div className={`header-container ${styles.headerContainer}`}>
          <Header />
        </div>
        <div className={`${styles.bodyContainer}`}>
          <div className="h-[100%] flex flex-col justify-center items-center">
            <div className={`${styles.errorImage500} pt-[20px]`}>
              <img src="/assets/images/500Error.png" alt="oops" width={'100%'} height={'100%'} />
            </div>
            <div className={`${styles.errorMsg}`}>
              <div>Sorry, we're currently experiencing a</div>
              <div>digital hiccup. We'll be back in a jiffy!</div>
            </div>
          </div>
          <div className={`${styles.footer}`}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page500;
