import React from 'react';
import styles from './Section5Screen.module.scss';

const Section5Screen = () => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contentContainer}`}>
        <div className={`${styles.titleContainer}`}>
          <div className={`${styles.titleText}`}>Express and engage in your interests</div>
        </div>
        <div className={`${styles.tagsContainer}`}>
          <div className={`${styles.tagsContent}`}>
            <div className={`${styles.tag} ${styles.tag1}`}>#Lifestyle</div>
            <div className={`${styles.tag} ${styles.tag2}`}>#KPop</div>
            <div className={`${styles.tag} ${styles.tag3}`}>#Food</div>
            <div className={`${styles.tag} ${styles.tag4}`}>#Music</div>
            <div className={`${styles.tag} ${styles.tag5}`}>#Finance</div>
            <div className={`${styles.tag} ${styles.tag6}`}>#Poetry</div>
          </div>
        </div>
        <img
          loading="lazy"
          className={`${styles.interestsFDesignImg}`}
          src={'/assets/images/interestsFDesign.png'}
          alt="interestsFDesign Img"
        ></img>
        <img loading="lazy" className={`${styles.starImg}`} src={'/assets/images/star.png'} alt="Star Img"></img>
        <div className={`${styles.lottiePlayer}`}>
          <lottie-player
            src="/assets/others/CommunityAndInterests.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div className={`${styles.bodyContainer}`}>
          <div className={`${styles.textContainer}`}>
            <div>
              Your interest interests us. So whether you want to create pods or just listen to them, simply choose your
              interests and we will personalize your feed with what you like.
            </div>
            <div>We have women creating content in genres like music, kpop, comedy, spoken-word and more.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5Screen;
