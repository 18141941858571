import { appStoreSingularTrackingUrl, handleAppInstallConversion, playStoreSingularTrackingUrl } from '../../constants';
import styles from './FooterSocialInfo.module.scss';
import React from 'react';

const FooterSocialInfo = () => {
  return (
    <>
      <div className={`${styles.bottomContainer}`}>
        <div className={`${styles.bottomText1}`}>Have feedback to share? Reach out to us on :</div>
        <div className={`${styles.socialIconsContainer}`}>
          <div
            className={`cursor-pointer`}
            onClick={() => {
              handleAppInstallConversion(appStoreSingularTrackingUrl);
            }}
          >
            <div loading="lazy" className={`${styles.appStoreBtnImage}`} />
          </div>
          <div
            className={`cursor-pointer`}
            onClick={() => {
              handleAppInstallConversion(playStoreSingularTrackingUrl);
            }}
          >
            <div loading="lazy" className={`${styles.playStoreBtnImage}`} />
          </div>
        </div>
        <div className={`${styles.bottomText2}`}>
          or E-mail us your queries on{' '}
          <span className={`${styles.bottomText3}`}>
            <a href="mailto:services@arre.co.in" rel={'noreferrer'} target={'_blank'}>
              services@arre.co.in
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default FooterSocialInfo;
